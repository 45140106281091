*{
  box-sizing: border-box;
}

body{
  margin:0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size:1rem;
  font-weight:normal;
  line-height:1.5;
  color:#fff;
  overflow-x:hidden;
}

.v-header{
  height:100vh;
  display:flex;
  align-items:center;
  color:#fff;
}

.container{
  max-width:960px;
  padding-left:1rem;
  padding-right:1rem;
  margin:auto;
  text-align:center;
}

.fullscreen-video-wrap{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  overflow:hidden;
}

.fullscreen-video-wrap video{
  min-height:100%;
  min-width:100%;
}

.header-overlay{
  height:100vh;
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  z-index:1;
  background:#f16136;
  opacity:0.3;
}

.header-content{
  z-index:2;
  opacity: 0;
  animation-name: show-header;
  animation-duration: 3s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes show-header {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.header-content h1{
  font-size:50px;
  margin-bottom:0;
}

.header-content p{
  font-size:1.2rem;
  display:block;
  padding-bottom:2rem;
}

.btn{
  background: #f58631;
  color:#fff;
  font-size:1.2rem;
  padding: 1rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
}

.btn:hover {
  background: #fcbb69;
}

.section{
  display: flex;
}

.section-b{
  margin: 0;
  margin-left: -1px;
  width: 40%;
  background:#606062;
  color:#fff;

  form {
    //background-color:rgba(255,255,255,0.50);
    width:80%;
    margin:auto;
    padding:1em 0;
    // border:1px solid;
    // border-radius:10px;



    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] { -moz-appearance:textfield; }

    h2{
      margin-bottom:0.5em;
      font-size:30px;
      font-family: centurygothic, CenturyGothic, AppleGothic, sans-serif;
      text-align:center;
    }
    p{
      margin:0px;
    }
    label{
      display:block;
      float: left;
      color: #fff;
    }
    input{
      height:2rem;
      margin-bottom:1em;
      border-radius: 7px;
      box-shadow: 0px 3px 10px 1px #727376;
    }
    textarea{
    border-radius: 7px;
      box-shadow: 0px 3px 10px 1px #727376;
    }
    button{
      height:2rem;
      margin-top:2em;
      border:0;
      border-radius:5px;
      text-transform: uppercase;
      font-weight:bold;
      color: #fff;
      background: #f58631; /* For browsers that do not support gradients */
        // background: -webkit-linear-gradient(#fcbb69, #f16136);
        // background: -o-linear-gradient(#fcbb69, #f16136);
        // background: -moz-linear-gradient(#fcbb69, #f16136);
        // background: linear-gradient(#fcbb69, #f16136);
    }
    button:hover,  button:focus{
      background: #f27634;
      color:#fff;
      outline:0;
      transition: background-color 0.1s ease-out;
    }
    button, input, textarea{
      width:100%;
    }
  }

  h6{
    color:#fff;
    font-size:0.6rem;
    text-align: center;
  }
}

.section-a {
  margin: 0;
  padding: 0;
  width: 80%;

  img {
    width: 100%;
    height: 100%;
  }
}

.alert{
  text-align: center;
  padding:10px;
  background:#79c879;
  color:#fff;
  margin-bottom:10px;
  display:none;
}
